import React from 'react';
import styles from "./styles.module.css";
import {cleaningSectionRefs} from "../content/servicePageDropdownTabs";
import Sp1 from "./Sp1";
import Sp2 from "./Sp2";
import Sp3 from "./Sp3";
import Sp4 from "./Sp4";
import Sp5 from "./Sp5";
import Sp6 from "./Sp6";
import Sp7 from "./Sp7";
import Sp8 from "./Sp8";
import Sp9 from "./Sp9";
import Sp10 from "./Sp10";


const CleaningListPage = () => {
    const [activeTab, setSActiveTab] = React.useState<number>(0);

    const renderPage = () => {
        switch (activeTab) {
            case 0: return <Sp1/>
            case 1: return <Sp2/>
            case 2: return <Sp3/>
            case 3: return <Sp4/>
            case 4: return <Sp5/>
            case 5: return <Sp6/>
            case 6: return <Sp7/>
            case 7: return <Sp8/>
            case 8: return <Sp9/>
            case 9: return <Sp10/>
        }
    }

    return (
        <div className={styles.wrapper} style={{paddingTop: '60px'}}>
            {cleaningSectionRefs.map((sectionRef, index) => (<h4 key={sectionRef} className={styles.href} onClick={() => setSActiveTab(index)}>{sectionRef}</h4>))}
            {renderPage()}
        </div>
    );
};

export default CleaningListPage;