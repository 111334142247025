import React, {useEffect} from 'react';
import styles from "./styles.module.css";
import { servicePage2} from "../content/Text";

const Sp2 = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    return (
        <div className={styles.wrapper}  style={{paddingTop: '40px'}}>
            <div className={styles.block}>
            <h1 className={styles.mainTitle}>{servicePage2.firstBlockTitle}</h1>
                <p className={styles.paragraph}>{servicePage2.firstBlock1Paragraph}</p>
            </div>
            <div className={styles.block}>
                <h2 className={styles.mainTitle}>{servicePage2.secondBlockTitle}</h2>
                <p className={styles.paragraph}>{servicePage2.secondBlock1Paragraph}</p>
                <ul className={styles.list}>{servicePage2.secondBlockParagraphList.map(((listItem, index) => <li
                    key={listItem}>{listItem}</li>))}</ul>
            </div>
            <div className={styles.block}>
                <h2 className={styles.mainTitle}>{servicePage2.thirdBlockTitle}</h2>
                <p className={styles.paragraph}>{servicePage2.thirdBlock1Paragraph}</p>
                <p className={styles.paragraph}>{servicePage2.thirdBlock2Paragraph}</p>
                <p className={styles.paragraph}>{servicePage2.thirdBlock3Paragraph}</p>
                <p className={styles.paragraph}>{servicePage2.thirdBlock4Paragraph}</p>
            </div>
        </div>
    );
};

export default Sp2;