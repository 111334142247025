import React from 'react';
import styles from './styles.module.css'
import {mainPageText} from "../content/Text";
import Form from "../components/Form/Form";

const MainPage = () => {



    return (
        <div className={styles.wrapper}>
            <h1 className={styles.mainTitle}>{mainPageText.firstBlockTitle}</h1>
            <div className={styles.block}>
                <p className={styles.paragraph}>{mainPageText.firstBlock1Paragraph}</p>
                <p className={styles.paragraph}>{mainPageText.firstBlock2Paragraph}</p>
            </div>
            <Form />
            <div className={styles.block}>
            <h2>{mainPageText.secondBlockTitle}</h2>
                <ul className={styles.list}>{mainPageText.secondBlockParagraphList.map(((listItem, index) => <li key={listItem}>{listItem}</li>))}</ul>
            </div>
            <div className={styles.block}>
                <h2>{mainPageText.thirdBlockTitle}</h2>
                <p className={styles.paragraph}>{mainPageText.thirdBlock1Paragraph}</p>
                <p className={styles.paragraph}>{mainPageText.thirdBlock2Paragraph}</p>
            </div>
        </div>
    );
};

export default MainPage;