import React from 'react';
import styles from './styles.module.css'
import {Map, Placemark} from "react-yandex-maps";
import {contacts} from "../content/Text";


const ContactsPage = () => {
    return (
        <div className={styles.wrapper}>
            <div >
                <Map defaultState={{ center: [55.807468107885974, 37.540991926441606], zoom: 17 }} width={1000} height={600}>
                    <Placemark defaultGeometry={[55.807468107885974, 37.540991926441606]} />
                </Map>
            </div>
            <h1 className={styles.mainTitle}>{contacts.phone}</h1>
            <h1 className={styles.mainTitle}>{contacts.address}</h1>
            <h1 className={styles.mainTitle}>{contacts.email}</h1>
        </div>
    );
};

export default ContactsPage;