import styles from './styles.module.css'
import Header from "./Header/Header";
import Footer from "./Footer/Footer";
import { useState } from "react";
import AboutPage from "../pages/AboutPage";
import Purchases from "../pages/Purchases";
import ContactsPage from "../pages/ContactsPage";
import ServicePage from "../pages/ServicePage";
import MainPage from "../pages/MainPage";
import React from 'react';

const Main = () => {
  const [activeTab, setActiveTab] = useState<number>(0);
  const [isDropdownOpen, setDropdownOpen] = useState<boolean>(false);

  const renderPage = () => {
    switch (activeTab) {
      case 0: return <MainPage/>
      case 1: return <ServicePage isDropdownOpen={isDropdownOpen} setDropdownOpen={setDropdownOpen}/>
      case 2: return <Purchases/>
      case 3: return <AboutPage/>
      case 4: return <ContactsPage/>
    }
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.backgroundImage} />
      <Header isDropdownOpen={isDropdownOpen} setActiveTab={setActiveTab} setDropdownOpen={setDropdownOpen}/>
        {renderPage()}
      <Footer />
    </div>
  );
};

export default Main;