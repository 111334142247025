import React, {useEffect} from 'react';
import styles from "./styles.module.css";
import {servicePage10} from "../content/Text";

const Sp10 = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])


    return (
        <div className={styles.wrapper} style={{paddingTop: '40px'}}>
            <div className={styles.block}>
                <h1 className={styles.mainTitle}>{servicePage10.firstBlockTitle}</h1>
                <p className={styles.paragraph}>{servicePage10.firstBlock1Paragraph}</p>
                <p className={styles.paragraph}>{servicePage10.firstBlock2Paragraph}</p>
                <p className={styles.paragraph}>{servicePage10.firstBlock3Paragraph}</p>
                <ul className={styles.list}>{servicePage10.firstBlock1List.map(((listItem, index) => <li
                    key={listItem}>{listItem}</li>))}</ul>
                <p className={styles.paragraph}>{servicePage10.firstBlock4Paragraph}</p>
            </div>
        </div>
    );
};

export default Sp10;