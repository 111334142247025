import React from 'react';
import style from './style.module.css'
import emailjs from '@emailjs/browser';
import moment from "moment";
import ReCAPTCHA from 'react-google-recaptcha';
import Spinner from "../Spinner/Spinner";

const Form = () => {
    const [form, setForm] = React.useState({name: '', email: '', tel: '', comment: ''});
    const [isSuccess, setIsSuccess] = React.useState(false);
    const [isFailure, setIsFailure] = React.useState(false);
    const [isShowInvalidSign, setIsShowInvalidSign] = React.useState(false);
    const [captchaNecessary, setCaptchaNecessary] = React.useState(false);
    const [isNameValid, setIsNameValid] = React.useState(true);
    const [isEmailValid, setIsEmailValid] = React.useState(true);
    const [captcha, setCaptcha] = React.useState(null);
    const [isSending, setIsSending] = React.useState(false);

    const handleChangeName = (e: React.ChangeEvent<HTMLInputElement>) => {
        setForm((prev) => ({...prev, name: e.target.value}));
        setIsNameValid(true);
    }

    const handleChangeEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
        setForm((prev) => ({...prev, email: e.target.value}));
        setIsEmailValid(true);
    }

    const handleChangeTel = (e: React.ChangeEvent<HTMLInputElement>) => {
        setForm((prev) => ({...prev, tel: e.target.value}));
    }

    const handleChangeComment = (e: React.ChangeEvent<HTMLInputElement>) => {
        setForm((prev) => ({...prev, comment: e.target.value}));
    }

    const validateName = () => {
        if (form.name.length === 0) {
            setIsNameValid(false);
            return false
        }
        setIsNameValid(true)
        return true
    }

    const validateEmail = () => {
        if (form.email.length === 0 || !RegExp(/^\S+@\S+\.\S+$/).exec(form.email)) {
            setIsEmailValid(false)
            return false
        }
        setIsEmailValid(true)
        return true
    }

    const showInvalidSign = () => {
        setIsShowInvalidSign(true);
        setTimeout(() => setIsShowInvalidSign(false), 3000);
    }

    const showSuccessSign = () => {
        setIsSuccess(true);
        setTimeout(() => setIsSuccess(false), 3000);
    }

    const showFailureSign = () => {
        setIsFailure(true);
        setTimeout(() => setIsFailure(false), 3000);
    }

    const showCaptchaNecessary = () => {
        setCaptchaNecessary(true)
        setTimeout(() => setCaptchaNecessary(false), 3000)
    }

    const handleSendClick = async (e: React.MouseEvent) => {
        e.preventDefault()

        const isNameFieldValid = validateName()
        const isEmailFieldValid = validateEmail()
        if (!isNameFieldValid || !isEmailFieldValid) {
            showInvalidSign()
            return
        }
        if (!captcha) {
            showCaptchaNecessary()
            return
        }
        const dataToSend = {
            name: form.name,
            email: form.email,
            tel: form.tel,
            comment: form.comment,
            time: moment().utcOffset(3).format('дата DD:MM:YYYY время HH:mm')
        }
        try{
            setIsSending(true);
            await emailjs.send('service_rsc.leeds', 'template_osizpqb', dataToSend, {publicKey: 'vy1fmHbYC4wcQD-za', blockHeadless: true, limitRate: {throttle: 60000}})
            showSuccessSign()
        }
        catch {
            showFailureSign()
        }
        finally {
            setIsSending(false);
        }
    }

    const invalidStyle = {border: '2px solid red'};

    return (
        <form className={style.formWrapper}>
            <p className={style.title}>Подайте заявку</p>
            <div className={style.formFloor}>
                <input className={style.inputField} style={isNameValid ? {} : invalidStyle}
                       placeholder={isNameValid ? "Ваше имя" : "Имя - обязательное поле"} value={form.name}
                       onChange={handleChangeName} onBlur={validateName} maxLength={60}/>
                <input className={style.inputField} style={isEmailValid ? {} : invalidStyle}
                       placeholder={isEmailValid ? "Ваш e-mail" : "Email - обязательное поле"} value={form.email}
                       onChange={handleChangeEmail} onBlur={validateEmail} maxLength={60}/>
            </div>
            <div className={style.formFloor}>

                <input className={style.inputField} placeholder="Ваш телефон (необязательно)" value={form.tel}
                       onChange={handleChangeTel} maxLength={20}/>
                <input className={style.inputField} placeholder="Примечание (необязательно)" value={form.comment}
                       onChange={handleChangeComment} maxLength={500}/>
            </div>
                <ReCAPTCHA sitekey="6LdUe58qAAAAALtfsT7vHnL57NsqLjc3Zq_mKuvb"
                           onChange={(value: React.SetStateAction<null>) => setCaptcha(value)}/>
                <button disabled={isSending} className={style.sendButton} onClick={handleSendClick}>{isSending ? <Spinner /> : 'Отправить'}</button>
                {isSuccess && <div className={style.successSign}>Заявка успешно отправлена</div>}
                {isFailure && <div className={style.sendFailure}>Не удалось отправить. Попробуйте чуть позже</div>}
                {isShowInvalidSign && <div className={style.sendFailure}>Заполните обязательные поля</div>}
                {captchaNecessary && <div className={style.sendFailure}>Поставьте галочку, что вы не робот</div>}
        </form>
);
};

export default Form;