import React, {useEffect} from 'react';
import styles from "./styles.module.css";
import {servicePage11} from "../content/Text";

const TechnicalExpSection = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    return (
        <div className={styles.wrapper}>
            <div className={styles.block}>
                <h1 className={styles.mainTitle}>{servicePage11.firstBlockTitle}</h1>
                <p className={styles.paragraph}>{servicePage11.firstBlock1Paragraph}</p>
                <p className={styles.paragraph}>{servicePage11.firstBlock2Paragraph}</p>
                <ul className={styles.list}>{servicePage11.firstBlock1List.map(((listItem, index) => <li
                    key={listItem}>{listItem}</li>))}</ul>
            </div>
            <div className={styles.block}>
                <h1 className={styles.mainTitle}>{servicePage11.secondBlockTitle}</h1>
                <h5 className={styles.paragraph}>{servicePage11.secondBlock1Paragraph}</h5>
                <ul className={styles.list}>{servicePage11.secondBlock1List.map(((listItem, index) => <li
                    key={listItem}>{listItem}</li>))}</ul>
                <h5 className={styles.paragraph}>{servicePage11.secondBlock2Paragraph}</h5>
                <ul className={styles.list}>{servicePage11.secondBlock2List.map(((listItem, index) => <li
                    key={listItem}>{listItem}</li>))}</ul>
                <h5 className={styles.paragraph}>{servicePage11.secondBlock3Paragraph}</h5>
                <ul className={styles.list}>{servicePage11.secondBlock3List.map(((listItem, index) => <li
                    key={listItem}>{listItem}</li>))}</ul>
                <h5 className={styles.paragraph}>{servicePage11.secondBlock4Paragraph}</h5>
                <ul className={styles.list}>{servicePage11.secondBlock4List.map(((listItem, index) => <li
                    key={listItem}>{listItem}</li>))}</ul>
                <h5 className={styles.paragraph}>{servicePage11.secondBlock5Paragraph}</h5>
                <ul className={styles.list}>{servicePage11.secondBlock5List.map(((listItem, index) => <li
                    key={listItem}>{listItem}</li>))}</ul>
            </div>
        </div>
    );
};

export default TechnicalExpSection;