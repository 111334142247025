import React from 'react';
import style from './style.module.css'

const Spinner = () => {
    return (
        <div className={style['lds-ellipsis']}>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
    );
};

export default Spinner;