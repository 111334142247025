import React, {SetStateAction, useState} from 'react';
import styles from './styles.module.css'
import ServicePageDropdown from "../components/ServicePageAsideBar/ServicePageDropdown";
import CleaningSection from "./Sp1";
import Sp2 from "./Sp2";
import Sp3 from "./Sp3";
import Sp4 from "./Sp4";
import Sp5 from "./Sp5";
import Sp6 from "./Sp6";
import Sp7 from "./Sp7";
import Sp8 from "./Sp8";
import Sp9 from "./Sp9";
import Sp10 from "./Sp10";
import TechnicalExpSection from "./TechnicalExpSection";
import BuildAndRepairSection from "./BuildAndRepairSection";
import Fade from "../components/ui/Fade";
import CleaningListPage from "./CleaningListPage";

type ServicePageProps = {
    isDropdownOpen: boolean,
    setDropdownOpen: React.Dispatch<SetStateAction<boolean>>
}

const ServicePage = ({isDropdownOpen, setDropdownOpen}: ServicePageProps) => {
    const [activeTab, setActiveTab] = useState<number>(0);

    React.useEffect(() => {
        setDropdownOpen(true)
        return () => {
            setDropdownOpen(false)
        }
    }, [])

    const handleFadeClick = () => {
        setDropdownOpen(false);
    }

    const renderPage = () => {
        switch (activeTab) {
            case 0: return <CleaningListPage/>
            case 1: return <TechnicalExpSection/>
            case 2: return <BuildAndRepairSection/>
        }
    }

    return (
        <div className={styles.wrapper}>
            {isDropdownOpen && <Fade onClick={handleFadeClick}/>}
            {isDropdownOpen && <ServicePageDropdown setActiveTab={setActiveTab} setDropdownOpen={setDropdownOpen}/>}
            {renderPage()}
        </div>
    );
};

export default ServicePage;